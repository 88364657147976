$lang = $('html').attr('lang');

$('.has-children > a').on('click', function(event) {
    event.preventDefault();

    $list = $(this).parent().find("ul");

    if ($list.is(':visible')) {
        $list.hide('slideUp');
    } else {
        $list.show('slideDown');
    }
});

$contactForm = $('.contact-form');

if ($contactForm.length > 0 ) {
    $('#datetimepicker1').datetimepicker({
        locale: $lang,
        format: 'DD/MM/YYYY'
    });

    $contactForm.validate({
        submitHandler: function(form) {
            var ladda = Ladda.create($contactForm.find('button.btn')[0]);
            ladda.start();

            $.ajax({
                type: 'POST',
                url: $contactForm.attr('action'),
                data: $contactForm.serialize()
            }).done(function (response) {
                ladda.stop();
                $contactForm.find('.step1').hide('slow');
                $contactForm.find('.step2').show('slow');
            }).fail(function (response) {
                ladda.stop();
                if (response.responseJSON) {
                    console.log(response.responseJSON);
                } else {
                    console.log({
                        error: 'Coś poszło nie tak, serwer dostał zadyszki. Spróbuj ponownie za chwilę, a jeśli problem będzie się powtarzał skontaktuj się z nami'
                    });
                }
            });
        }
    });
}

$('#cookies_policy_submit').click(function () {
    Cookies.set('cookies_policy', 'true', { expires: 365 });
    $('#cookies-policy').hide("slow");
});
